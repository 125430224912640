import { useState, useEffect, useCallback } from 'react'

import { BUILD_ENV } from 'gatsby-env-variables'

import { useLead, useProposal } from '@itau-loans-www/shopping/src/hooks'
import { http } from '@itau-loans-www/shopping/src/services'
import analytics from '@mobi/libraries/analytics'
import { goTo } from 'utils'

const useName = ({ hasDocument }) => {
  const { cleanProduct } = useProposal()
  const [isButtonLoading, setButtonLoading] = useState(false)

  const { changeOffers, changeLeadData, leadData } = useLead()

  const isProd = BUILD_ENV === 'production'

  const cleanOlderProposalData = useCallback(() => {
    changeLeadData({
      previousStep: 'home'
    })

    if (hasDocument) {
      return
    }

    http.cleanAuth()
    cleanProduct()
    changeOffers({
      hero: undefined,
      others: undefined,
      desiredLoanValue: undefined
    })
  }, [changeOffers, changeLeadData, cleanProduct, hasDocument])

  useEffect(() => {
    cleanOlderProposalData()
  }, [cleanOlderProposalData])

  useEffect(() => {
    analytics.track('pageLoad', {
      currentPage: 'formulario-dados-complementares-nome',
      customLayer: { variant: leadData?.vwoVariant }
    })
  }, [])

  const onSubmit = async ({ name }) => {
    setButtonLoading(true)

    changeLeadData({
      name,
      previousStep: '/proposta/nome'
    })

    analytics.track('buttonClick', {
      currentPage: 'formulario-dados-complementares-nome',
      detail: 'click-step-basic'
    })

    goTo('/proposta/email')

    setButtonLoading(false)
  }

  return {
    onSubmit,
    isButtonLoading,
    isProd
  }
}

export default useName
