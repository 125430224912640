import { useForm } from 'react-hook-form'

import {
  Navigation,
  OptIn,
  Input,
  If,
  Shimmer
} from '@itau-loans-www/shopping/src/components'
import { useLead } from '@itau-loans-www/shopping/src/hooks'
import ProposalLayout from 'layouts/Proposal'

import useName from './hooks'
import './proposal-name.scss'

const Name = ({ pageContext }) => {
  const {
    leadData: { name, cpf }
  } = useLead()

  const initialValues = {
    name: name ?? ''
  }

  const {
    handleSubmit,
    control,
    formState: { isValid }
  } = useForm({
    mode: 'onChange',
    defaultValues: initialValues
  })
  const hasDocument = !!cpf

  const { onSubmit, isButtonLoading, isProd } = useName({
    hasDocument
  })

  return (
    <ProposalLayout pageContext={pageContext}>
      <If
        condition={isButtonLoading}
        renderIf={
          <>
            <Shimmer
              margin="10px 0 40px 0"
              height="66px"
              heightDesktop="30px"
            />
            <Shimmer margin="0 0 30px 0" height="66px" />
            <Shimmer height="66px" />
            <Shimmer margin="24px 0" height="156px" />
            <Shimmer margin="30px 0 10px 0" widthDesktop="50%" height="50px" />
          </>
        }
        renderElse={
          <>
            <h2 className="proposal-nome__subtitle">Boas-vindas ao Itaú!</h2>
            <h1 className="proposal-title">{'Como você se chama?'}</h1>
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
              <If
                condition={isProd}
                renderIf={
                  <Input.Name
                    label="Nome completo"
                    data-cy="cy-input-name"
                    id="name"
                    name="name"
                    autoComplete="on"
                    control={control}
                  />
                }
                renderElse={
                  <Input.Text
                    label="Nome completo"
                    data-cy="cy-input-name"
                    id="name"
                    name="name"
                    autoComplete="on"
                    control={control}
                  />
                }
              />

              <OptIn />
              <Navigation
                submit
                nextStep
                isNextButtonDisabled={!isValid}
                nextButtonId={'basic-data-next-btn'}
                dataCy="cy-btn-next"
                nextButtonText="Continuar simulação"
              />
            </form>
          </>
        }
      />
    </ProposalLayout>
  )
}

export default Name
